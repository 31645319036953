import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { uploadPhotos, getPhotos } from './api';
import PhotosList from './components/PhotosList';
import File from './components/File';
import feuillage from './img/feuillage.svg';
import './App.css';

const App = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [showHeader, setShowHeader] = useState(false);
  const [isFinishedEnterAnim, setIsFinishedEnterAnim] = useState(false);
  const [isFinishedMoving, setIsFinishedMoving] = useState(false);
  const [showThanksText, setShowThanksText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchPhotos();
    setShowHeader(true);
    const timer = setTimeout(() => {
      setIsFinishedEnterAnim(true);
    }, 2000);
    setTimeout(() => {
      setIsFinishedMoving(true);
    }, 3000);
    return () => clearTimeout(timer); // nettoyer le setTimeout si le composant est démonté
  }, []);
  

  const fetchPhotos = async () => {
    try {
      const photosData = await getPhotos();
      setPhotos(photosData);
    } catch (error) {
      console.error('Erreur lors de la récupération des photos:', error.message);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      setShowThanksText(false);
      let formData = new FormData();
      if (selectedFiles.length > 3) {
        alert('Veuillez sélectionner 3 photos maximum.');
        setIsLoading(false);
        return;
      }  
  
      for (const element of selectedFiles) {
        formData.append('photos', element);
      }
  
      await uploadPhotos(formData);
      await fetchPhotos();
      setShowThanksText(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert('An error occurred while uploading the photos.');
      console.error(error);
    }
  };

  return (
    <div className="container mt-5">
      <div className={`Header ${showHeader ? 'show' : ''} `}>
        <header className={`Header-header ${isFinishedEnterAnim ? 'finished' : ''}`}>
          <div className='carole-franck'>
            <h1 className="text-center">Carole & Franck</h1>
            <h2 className="date text-center">26.08.2023</h2>
          </div>
        </header>
      </div>
      {isFinishedMoving && (
      <div className='content'>
        <Tabs defaultActiveKey="import_pics" id="tabs-example">
          <Tab eventKey="import_pics" title="Ajouter des photos">
            <div className="text-center mb-4">
              <img src={feuillage} alt="feuillage" style={{width: '45vw'}}/>
            </div>
            <File
              selectedFiles={selectedFiles}
              handleFileChange={handleFileChange}
              handleUpload={handleUpload}
              showThanksText={showThanksText}
              isLoading={isLoading}
            />
          </Tab>
          <Tab eventKey="all_pics" title="Voir les photos">
            <div className="text-center mb-3">
              <img src={feuillage} alt="feuillage" style={{width: '50vw'}}/>
            </div>
            <PhotosList photos={photos} />
          </Tab>
        </Tabs>
      </div>
      )}
    </div>
  );
};

export default App;
