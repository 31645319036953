import React, { useState, useEffect } from 'react';
import { downloadPhotos } from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const PhotosList = ({ photos }) => {
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectAllText, setSelectAllText] = useState('Tout sélectionner');
  const [currentPage, setCurrentPage] = useState(1);
  const photosPerPage = 3; // Nombre de photos par page
  const indexOfLastPhoto = currentPage * photosPerPage;
  const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
  const currentPhotos = photos.slice(indexOfFirstPhoto, indexOfLastPhoto);

  useEffect(() => {
    if (selectedPhotos.length === photos.length) {
      setSelectAllText('Tout désélectionner');
    } else {
      setSelectAllText('Tout sélectionner');
    }
  }, [selectedPhotos, photos]);


  const handleSelectAll = () => {
    if (selectAllText === 'Tout désélectionner') {
      setSelectedPhotos([]);
    } else {
      setSelectedPhotos(photos.map(photo => photo));
    }
  };

  const handleImageClick = (photo) => {
    if (selectedPhotos.includes(photo)) {
      setSelectedPhotos(prevSelected =>
        prevSelected.filter(selectedPhoto => selectedPhoto !== photo)
      );
    } else {
      setSelectedPhotos(prevSelected => [...prevSelected, photo]);
    }
  };

  const handleDownload = async () => {
    await downloadPhotos(selectedPhotos);
    console.log('Downloading photos...');
  };
  

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(photos.length / photosPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="container">
      <div className="mb-3">
        <div className="col-auto text-center">
          <button
            type="button"
            className="btn mb-3 mt-3"
            onClick={handleSelectAll}
            style={{ borderRadius: '0%', borderColor: '#96BFAE', color: '#96BFAE' }}
          >
            {selectAllText +' '+ selectedPhotos.length +'/' + photos.length}
          </button>
          <button
            type="button"
            className="btn mb-3 mt-3 download-btn"
            onClick={handleDownload}
            style={{ borderRadius: '0%', backgroundColor: '#96BFAE', borderColor: '#96BFAE', color:'white' }}
            disabled={selectedPhotos.length === 0}
          >
            Télécharger
          </button>
        </div>
        <div className="row text-center">
          {currentPhotos.length > 0 ? currentPhotos.map(photo => (
            <div className="position-relative mb-2 test" key={photo}>
              <img
                src={`https://stockage-photo-mariage.s3.eu-west-3.amazonaws.com/${photo}`}
                alt={photo}
                className={`img-fluid ${selectedPhotos.includes(photo) ? 'selected' : ''}`}
                onClick={() => handleImageClick(photo)}
                style={{width: '100vw'}}
              />
              {selectedPhotos.includes(photo) && (
                <FontAwesomeIcon className='selected-icon' icon={faCheck} />
              )}
            </div>
          )) : (
            <p>Aucune photo pour le moment.</p>
          )}
        </div>
      </div>
      <nav>
        <ul className="pagination justify-content-center d-flex flex-wrap">
          {pageNumbers.map(number => (
            <li key={number} className="page-item">
              <a onClick={() => paginate(number)} className="page-link" href={`#${number}`}>
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default PhotosList;
