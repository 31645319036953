const BASE_URL = 'https://mariage-franck-carole.vercel.app/api'; // Remplacez par l'URL de votre serveur backend

export const uploadPhotos = async (photosData) => {
  try {
    const formData = new FormData();
    photosData.forEach((photo) => {
      formData.append('photos', photo);
    });

    const response = await fetch(`${BASE_URL}/photos`, {
      method: 'POST',
      body: formData, // Utilisation de l'objet FormData pour envoyer les images
    });

    if (!response.ok) {
      throw new Error('Erreur lors de l\'insertion de la photo.');
    } else {
      const form = document.getElementById('formFileMultiple');
      form.value = '';
    }
    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};


export const getPhotos = async () => {
  try {
    const response = await fetch(`${BASE_URL}/photos`, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des photos.');
    }

    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};

export const downloadPhotos = async (photosToDownload) => {
  try {
    const response = await fetch(`${BASE_URL}/photos/download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ photos: photosToDownload }),
    });
    const blob = await response.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = 'mariage-carole-franck.zip';
    downloadLink.click();
  } catch (error) {
    console.error('An error occurred:', error);
  }
};