import React from 'react';
import franckCarole from '../img/carole_franck.jpeg';

const File = ({ selectedFiles, handleFileChange, handleUpload, showThanksText, isLoading }) => {

  return (
    <div className="container">
      <div className="mb-3">
        <input
          className="form-control"
          type="file"
          id="formFileMultiple"
          multiple
          accept="image/png, image/gif, image/jpeg"
          onChange={handleFileChange}
        />
        <div className="col-auto text-center">
          <button
            type="button"
            className="btn btn-success mb-3 mt-3"
            onClick={handleUpload}
            disabled={selectedFiles.length === 0 || isLoading}
            style={{
              borderRadius: '0%',
              backgroundColor: '#96BFAE',
              borderColor: '#96BFAE',
              position: 'relative',
            }}
          >
            {isLoading ? 'CHARGEMENT...' : 'AJOUTER'}
            {isLoading && (
              <div className="spinner-border spinner-border-sm text-light" role="status">
              </div>
            )}
          </button>
        </div>
        {showThanksText && 
          <div className="col-auto text-center">
            <p className="text-center">Photos ajoutées !</p>
          </div>
        }
        <div className="col-auto text-center">
          {showThanksText && <div className="thanks-text-img">Merci !</div>}
          <img src={franckCarole} alt="carole_franck" style={{ width: '80vw' }} />
        </div>
      </div>
    </div>
  );
};

export default File;
